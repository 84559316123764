

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
/* font-family: 'Sirin Stencil', cursive; */
}


body {
    overflow: hidden;
    background-color: #474fbf!important;
    width: 100vw;
}
.cursor{
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.548);
    z-index: 1000;
}